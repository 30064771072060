import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

const Card = styled.a`
  border-radius: 10px;
  background: #0b0c14;
  height: 12rem;
  width: 8rem;
  flex: 0 0 auto;
  text-decoration: none;
  box-shadow: rgba(11, 12, 20, 0.05) 0px 0px 11px 3px;
  @media screen and (max-width: 991px) {
    width: 8rem;
    height: 12rem;
  }
`
const TopCard = styled.div`
  background: white;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`
const ProductTitle = styled.h4`
  color: #efefef;
  font-weight: 500;
  letter-spacing: 1.4px;
  font-size: 0.8rem;
  padding: 0.7rem 0.75rem;
  margin-bottom: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Image = styled(GatsbyImage)`
  height: 7em;
`
const List = styled.div`
  min-width: auto;
  width: 100% ;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding-right: 1em;
  float: left;
  gap:1em;
  &:first-child {
    margin-left: 0;
  }
  &::-webkit-scrollbar {
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
  @media screen and (min-width: 900px) {
    flex-wrap:wrap;
    overflow: hidden;
  }
`
const ListWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
  display: inline-flex;
  @media screen and (min-width: 900px) {
    overflow-x: initial;
  }
`
const Title = styled.h3`
  letter-spacing: 1.6px;
  font-size: 1.3rem;
  font-weight: 900;
  margin-left: 1rem;
  padding: 1em 0;
  margin-bottom: 0;
  text-transform: capitalize;
`
const ProductCard = ({ brandname, brandlogo, url }) => {
  return (
    <Card href={url} target={url !== "#" ? "_blank" : undefined} rel="external">
      <TopCard>
        <Image
          imgStyle={{ objectFit: "contain" }}
          image={brandlogo?.localFile?.childImageSharp?.gatsbyImageData}
        />
      </TopCard>
      <ProductTitle>{brandname}</ProductTitle>
    </Card>
  );
}
export const ProductList = ({ title, children }) => (
  <>
    <Title>{title}</Title>
    <ListWrapper>
      <List>{children}</List>
    </ListWrapper>
  </>
)

export default ProductCard
