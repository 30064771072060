import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { PageTitle } from "../components/globals"
import ProductCard, { ProductList } from "../components/ProductCard"

const Merker = () => {
  const [categories, setCategory] = useState({})
  const { allStrapiBrand: strapiData } = useStaticQuery(graphql`query luoBrands {
  allStrapiBrand {
    edges {
      node {
        brandlogo {
          localFile{
            childImageSharp {
              gatsbyImageData(quality: 100, height: 100, layout: FULL_WIDTH)
            }
          }
        }
        type
        brandname
        url
      }
    }
  }
}
`)
  const { edges: brands } = strapiData
  useEffect(() => {
    const categories = {}
    brands.forEach(b => {
      categories[b.node.type] = [...(categories[b.node.type] ?? []), b.node]
    })
    setCategory(categories)
  }, [brands])

  return (
    <>
      <Layout
        title="Merker"
        description="Lambertseter Ur & Optikk tilbyr mange forskjellige merker for klokker, briller, solbriller og linser."
        keywords={[
          "linser",
          "briller",
          "klokker",
          "optikk",
          "ur",
          "lambertseter",
          "nordstrand",
          "optikk",
        ]}
      >
        <PageTitle>Våre merker</PageTitle>
        {Object.keys(categories).map(category => (
          <ProductList title={category}>
            {categories[category].map(data => (
              <ProductCard {...data} />
            ))}
          </ProductList>
        ))}
      </Layout>
    </>
  )
}
export default Merker
